import { _CONSTANTS } from '@components/common/constant';
import { jobSearchResultsInteractions } from '@components/common/event-tracker';
import { Action, ClickOption } from 'helper/eventTrackingUtils/constant';
import { createRef, useRef, useState } from 'react';
import FilterBtnGroup from './filterBtnGroup';
import { FilterWrap, MoreFilterLink } from './styled';

type Props = {
  selectedFilters: { [key: string]: Array<string> };
  jobFilters: Array<JobFilters>;
  hideDetails?: any;
  removeFilter: (_slug: string) => void;
  handeleFilterChange: (
    _filterName: string,
    _filterCategory: string,
    _name: string
  ) => string;
  handleCategoryFilter: (
    _filter: any,
    _categoryId: string,
    _categoryType: 'Parent' | 'Child'
  ) => void;
  showAllDropFilters?: boolean;
  isRCPortal?: boolean;
};

type JobFilters = {
  name: string;
  categoryWithCount: CategoryWithCount;
  slug: string;
};

type CategoryWithCount = {
  [key: string]: number;
};

const Filter = (props: Props) => {
  const { jobFilters, isRCPortal } = props;
  const [jobsFilter, setJobsFilter] = useState<any>(
    props.showAllDropFilters ? jobFilters : jobFilters.slice(0, 4)
  );
  const showFilterButtonMoreText = isRCPortal
    ? 'Show All Filters'
    : 'More Filters';
  const showFilterButtonLessText = isRCPortal ? 'Show Less' : 'Less';
  const [filterText, setFilterText] = useState(
    props.showAllDropFilters
      ? showFilterButtonLessText
      : showFilterButtonMoreText
  );
  const [isActive, setIsActive] = useState<null | number>(null);

  const filterBtnRef = useRef([]);
  filterBtnRef.current = jobsFilter.map(
    (_: any, index: number) => filterBtnRef.current[index] ?? createRef()
  );

  // Toggle dropdown
  const handleToggle = (index: number) => {
    props.hideDetails && props.hideDetails();
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  const showFilters = (e: any) => {
    e.preventDefault();

    props.hideDetails && props.hideDetails();
    let cookieValue;
    if (e.target.text === showFilterButtonMoreText) {
      jobSearchResultsInteractions(
        Action.Clicked,
        ClickOption.Show_All_Filters
      );
      setJobsFilter(jobFilters);
      setFilterText(showFilterButtonLessText);
      cookieValue = 'true';
    } else {
      jobSearchResultsInteractions(
        Action.Clicked,
        ClickOption.Showshow_Less_Filters
      );
      setJobsFilter(jobsFilter.slice(0, 4));
      setFilterText(showFilterButtonMoreText);
      cookieValue = 'false';
    }
    let secure = ' ;secure';
    document.cookie =
      _CONSTANTS.SET_SHOW_ALL_DROP_FILTERS +
      '=' +
      cookieValue +
      secure +
      '; path=/;';
  };
  return (
    <>
      <FilterWrap>
        {jobsFilter.map((item: any, index: number) => (
          <FilterBtnGroup
            key={index}
            {...item}
            onToggle={() => handleToggle(index)}
            active={isActive === index}
            className="toggle-btn"
            handleFilterChange={props.handeleFilterChange}
            handleCategoryFilter={props.handleCategoryFilter}
            removeFilter={props.removeFilter}
            selectedCategories={props.selectedFilters[item.slug.toLowerCase()]}
            selectedFilters={props.selectedFilters}
            filterRef={filterBtnRef.current[index]}
            setIsActive={setIsActive}
            isRCPortal={isRCPortal}
          />
        ))}
        <MoreFilterLink
          id="show-more-filter"
          href="#"
          role="button"
          onClick={showFilters}
          className="show-content"
          isRCPortal={isRCPortal}
        >
          {filterText}
        </MoreFilterLink>
      </FilterWrap>
    </>
  );
};

export default Filter;
