import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
export const JobPageWrapper = styled.div<{
  isCategoryPage?: boolean;
  isSearchPage?: boolean;
  isRemoteJobsPage?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isSearchPage }) =>
    isSearchPage ? 'repeat(1, auto)' : 'repeat(2, auto)'};
  gap: 3.2rem;
  margin: ${({ isCategoryPage }) =>
    isCategoryPage ? '0 auto 0.8rem' : '0 auto 2.4rem'};
  ${device.mobileLg} {
    grid-template-columns: repeat(1, auto);
    margin-bottom: 0.8rem;
    gap: 0.8rem;
    padding: ${({ isRemoteJobsPage, isCategoryPage }) =>
      (isCategoryPage || isRemoteJobsPage) && 0};
  }
  ${({ isSearchPage, isRemoteJobsPage, isCategoryPage }) =>
    (isSearchPage || isRemoteJobsPage || isCategoryPage) &&
    css`
      max-width: 119rem;
      margin: ${isRemoteJobsPage || isCategoryPage
        ? '0 auto 1.6rem'
        : '0 auto'};
      padding: ${isRemoteJobsPage || isCategoryPage ? 0 : '2.4rem 1.5rem 0'};
      ${device.mediumdesktopView} {
        max-width: 129.5rem;
      }
    `}
`;
export const JobGetStarted = styled.div<{ isSearchPage?: boolean }>`
  ${Mixin.Position({ position: 'relative' })}
  max-width: 52.2rem;
  padding: 2.4rem 11.3rem 1.7rem 2.4rem;
  overflow: hidden;
  ${({ theme: { JobPageDescriptionStyle } }) =>
    JobPageDescriptionStyle &&
    css`
      background: linear-gradient(
        94.33deg,
        ${JobPageDescriptionStyle?.BgColors?.JobGetStartedColor} 27.92%,
        ${JobPageDescriptionStyle?.BgColors?.JobGetStartedColor2} 67.4%
      );
      border: 0.1rem solid
        ${JobPageDescriptionStyle?.BdrColors?.JobGetStartedColor};
      box-shadow: 0 0 1rem 0
        ${JobPageDescriptionStyle?.BgColors?.JobGetStartedBoxShadow};
    `}
  ${device.tabletSm} {
    max-width: 100%;
  }
  ${device.mobileLg} {
    padding: 1.6rem;
    text-align: center;
    flex-wrap: wrap;
    padding-bottom: 2.4rem;
  }
  ${({ isSearchPage }) =>
    isSearchPage &&
    css`
      display: flex;
      justify-content: space-between;
      padding: 1.6rem 2.4rem 1.6rem 4.1rem;
      max-width: 100%;
      align-items: center;
    `}
`;
export const JobGetStartedHeading = styled.h4<{ isSearchPage?: boolean }>`
  display: inline-block;
  line-height: 3.7rem;
  text-transform: uppercase;
  margin-bottom: 0.7rem;
  ${({ theme: { JobPageDescriptionStyle } }) =>
    JobPageDescriptionStyle &&
    css`
      color: ${JobPageDescriptionStyle?.Colors?.JobGetStartedHeading};
      font-size: ${JobPageDescriptionStyle?.FontSizes?.JobGetStartedHeading};
      border-bottom: 0.4rem solid
        ${JobPageDescriptionStyle?.BdrColors?.JobGetStartedHeading};
    `}
  ${({ isSearchPage }) =>
    isSearchPage &&
    css`
      > span {
        display: block;
        text-transform: none;
      }
      font-size: 3rem;
      border-bottom: 0;
      margin-bottom: 1.3rem;
    `}
  ${device.mobileLg} {
    line-height: 3rem;
    padding-bottom: 0.8rem;
    ${({ theme: { JobPageDescriptionStyle }, isSearchPage }) =>
      JobPageDescriptionStyle &&
      css`
        font-size: ${isSearchPage
          ? '1.9rem'
          : JobPageDescriptionStyle?.FontSizes?.JobGetStartedHeadingMB};
        ${isSearchPage &&
        css`
          border-bottom: 0.2rem solid
            ${JobPageDescriptionStyle?.BdrColors?.JobGetStartedHeading};
        `}
      `}
  }
`;
export const JobGetStartedDescription = styled.p`
  line-height: 1.9rem;
  margin-bottom: 2.7rem;
  ${({ theme: { JobPageDescriptionStyle } }) =>
    JobPageDescriptionStyle &&
    css`
      font-size: ${JobPageDescriptionStyle?.FontSizes
        ?.JobGetStartedDescription};
      color: ${JobPageDescriptionStyle?.Colors?.JobGetStartedDescription};
    `}
  ${device.mobileLg} {
    margin-bottom: 1.6rem;
    ${({ theme: { JobPageDescriptionStyle } }) =>
      JobPageDescriptionStyle &&
      css`
        font-size: ${JobPageDescriptionStyle?.FontSizes
          ?.JobGetStartedDescriptionMB};
      `}
  }
`;
export const JobPagesDescriptionWrapper = styled.div`
  ${({ theme: { JobPageDescriptionStyle } }) =>
    JobPageDescriptionStyle &&
    css`
      background: ${JobPageDescriptionStyle?.BgColors?.JobPagesDescription};
      border: 0.1rem solid
        ${JobPageDescriptionStyle?.BdrColors?.JobPagesDescription};
    `}
  padding: 2rem;
  display: flex;
  align-items: center;
  ${device.mobileLg} {
    background-color: transparent;
    padding: 0;
    border: 0;
  }
`;
export const JobPagesDescription = styled.p`
  margin: 0;
`;

export const CompanyLogoWrap = styled.span`
  ${Mixin.Position({ value: '1rem 0 auto auto' })}
  margin:auto;
  ${device.mobileLg} {
    display: none;
  }
`;
export const JobContainListInfo = styled.ul`
  &.listItem {
    padding: 0 0 0 2.2rem;
    color: #f8f9fa;
    list-style: none;
    border-left: 0.4rem solid #67c75a;
  }
  ${device.mobileLg} {
    &.listItem {
      border-left: 0;
      padding: 0;
      margin: 0 0 1.6rem;
    }
  }
`;
export const JobContainLists = styled.li`
  font-size: 1.4rem;
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
  > span {
    color: #67c75a;
    font-weight: 700;
  }
  ${device.mobileLg} {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
    font-size: 1.2rem;
    > span {
      display: block;
    }
  }
`;
export const JobWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${device.mobileLg} {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
