import { Button } from '@license-admin/boldfjcomponents';
import {
  CompanyLogoWrap,
  JobContainListInfo,
  JobContainLists,
  JobGetStarted,
  JobGetStartedDescription,
  JobGetStartedHeading,
  JobPagesDescription,
  JobPagesDescriptionWrapper,
  JobPageWrapper,
  JobWrap,
} from './styled';

interface Props {
  remoteJobDescription?: string;
  isCategoryPage?: boolean;
  isSearchPage?: boolean;
  deviceType?: string;
  isLoggedIn?: boolean;
  isPremiumUser?: boolean;
  isRemoteJobsPage?: boolean;
}

const JobPageDescription: React.FC<Props> = ({
  remoteJobDescription,
  isCategoryPage,
  isSearchPage,
  deviceType,
  isLoggedIn,
  isPremiumUser,
  isRemoteJobsPage,
}) => {
  const searchPageData = {
    heading: '<span>Unlock full access to</span>Exclusive remote job listings',
    buttonName: 'Upgrade Today',
    list: [
      '<span>Fresh, hand-screened jobs added daily</span>',
      '<span>Ad-free, scam-free experience, verified listings</span>',
      '<span>Tailored jobs matches</span>',
    ],
  };
  const isMobileDevice = deviceType === 'mobile';
  return (
    <JobPageWrapper
      isCategoryPage={isLoggedIn}
      isSearchPage={isSearchPage || (isLoggedIn && !isPremiumUser)}
      isRemoteJobsPage={isRemoteJobsPage}
    >
      <JobGetStarted
        isSearchPage={isSearchPage || (isLoggedIn && !isPremiumUser)}
      >
        {isSearchPage || (isLoggedIn && !isPremiumUser) ? (
          <>
            <JobWrap>
              <JobGetStartedHeading
                isSearchPage={isSearchPage || (isLoggedIn && !isPremiumUser)}
                dangerouslySetInnerHTML={{ __html: searchPageData.heading }}
                id="unlock-full-access"
              />
              {isMobileDevice && (
                <JobContainListInfo className="listItem">
                  {searchPageData.list.map((items: any, index: number) => {
                    return (
                      <JobContainLists
                        key={`${index + 'containsList'}`}
                        dangerouslySetInnerHTML={{ __html: items }}
                        id={`fresh-hand-screened-jobs_${index}`}
                      />
                    );
                  })}
                </JobContainListInfo>
              )}
              <Button
                id="upgrade-remote-link"
                buttonType="primarygreen"
                text={searchPageData.buttonName}
                btnType="submit"
                clickHandler={() => {
                  window.location.href = `${process.env.NEXT_PUBLIC_ROOT_URL}/payment/index`;
                }}
              />
            </JobWrap>
            {!isMobileDevice && (
              <JobContainListInfo className="listItem">
                {searchPageData.list.map((items: any, index: number) => {
                  return (
                    <JobContainLists
                      key={`${index}`}
                      dangerouslySetInnerHTML={{ __html: items }}
                    />
                  );
                })}
              </JobContainListInfo>
            )}
          </>
        ) : (
          <>
            <JobGetStartedHeading id="find-your-best-match">
              Find your best match
            </JobGetStartedHeading>
            <JobGetStartedDescription id="find-the-remote-job">
              Find the remote job that best fits your schedule and career goals.
              It takes just a few seconds to refine the search.
            </JobGetStartedDescription>
            <Button
              id="get-started-link"
              buttonType="primarygreen"
              text="Get Started"
              btnType="submit"
              clickHandler={() => {
                window.location.href = `/wizard/welcome`;
              }}
            />
            <CompanyLogoWrap>
              <img
                src={`/blobcontent/rmc/images/job-page-laptop-image.png`}
                alt={`Find your best match`}
                loading="eager"
              />
            </CompanyLogoWrap>
          </>
        )}
      </JobGetStarted>
      {!isSearchPage && !isLoggedIn && (
        <JobPagesDescriptionWrapper id="job-page-description">
          <JobPagesDescription>{remoteJobDescription}</JobPagesDescription>
        </JobPagesDescriptionWrapper>
      )}
    </JobPageWrapper>
  );
};

export default JobPageDescription;
