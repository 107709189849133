import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const Select = styled.select<{
  isArrowFill?: boolean;
  borderRight?: boolean;
  isSearchFilter?: boolean;
  isborderRight?: boolean;
  isRCPortal?: boolean;
}>`
  padding: 0.6rem 3.6rem 0.6rem 1.2rem;
  border-radius: ${({ borderRight }) => !borderRight && '0.4rem'};
  display: block;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  width: 100%;
  ${({ theme: { FormControlStyle } }) =>
    FormControlStyle &&
    css`
      border: 0.1rem solid ${FormControlStyle.BdrColors.select};
      color: ${FormControlStyle.Colors.DarkColor};
    `};
  ${({
      isSearchFilter,
      isborderRight,
      isRCPortal,
      theme: { FormControlStyle },
    }) =>
    isSearchFilter &&
    css`
      ${isborderRight
        ? css`
            height: 4.2rem;
            border-radius: 0 0.3rem 0.3rem 0;
            border: 0.1rem solid ${FormControlStyle?.BdrColors?.selectCountry};
            border-left: 0;
          `
        : css`
            height: 3.7rem;
            border-right: 0;
          `}
      ${!isRCPortal &&
      css`
        ${device.tabletMd} {
          height: 3.4rem;
        }
      `}
    `}
`;

interface InputSearchProp {
  bdrcurve?: boolean;
  inputHeight?: string;
  isHeader?: boolean;
  borderRight?: boolean;
  isRCPortal?: boolean;
  isborderRight?: boolean;
  isSelectCountry?: boolean;
}
const customWidth = (
  isRCPortal?: boolean,
  isRemoteJobsPage?: boolean,
  isHeader?: boolean
) => {
  let widthValue;

  if (isRCPortal) {
    widthValue = isRemoteJobsPage ? '58.5%' : '47%';
  } else {
    widthValue = !isHeader && '100%';
  }
  return widthValue;
};
/* width: ${//customWidth(isRCPortal, isRemoteJobsPage, isHeader)}; //FOR BASELINE will remove once exeriment win. */
export const Styledform = styled.form<{
  isHeader?: boolean;
  isRCPortal?: boolean;
  isRemoteJobsPage?: boolean;
  isCategoryPage?: boolean;

}>`
  ${({ isRCPortal, isHeader, isRemoteJobsPage }) =>
    css`
      width: 56.21%; //FOR EXPERIMENT isAddStaticSearchList if its lose we'll place above comment width here.
      margin: 0 0 0.9rem;
    `}
  ${device.mobileTab} {
    margin: 0;
  }
  ${device.mobileLg} {
    width: 100%;
  }
`;

export const InputSearch = styled.input<InputSearchProp>`
  padding: ${({ isRCPortal }) =>
    isRCPortal ? '0.8rem 1.6rem' : '0.6rem 1.2rem'};
  outline: none;
  ${({
      theme: { SearchFilterStyle },
      bdrcurve,
      inputHeight,
      isHeader,
      borderRight,
      isRCPortal,
      isborderRight,
      isSelectCountry,
    }) =>
    SearchFilterStyle &&
    css`
      border-radius: ${!borderRight && '0.4rem'};
      background-color: ${isHeader
        ? SearchFilterStyle.BgColors.headerSearch
        : SearchFilterStyle.BgColors.search};
      border: 0.1rem solid
        ${isHeader
        ? SearchFilterStyle.BdrColors.transSearch
        : SearchFilterStyle.BdrColors.searchWrap};
      &::placeholder {
        color: ${SearchFilterStyle.Colors.searchWrap};
      }

      ${isRCPortal &&
      css`
        height: 4.2rem;
        &::placeholder {
          color: #646f82 !important;
          opacity: 1;
        }
        border-radius: ${isborderRight
          ? '0 0.3rem 0.3rem 0'
          : '0.3rem 0 0 0.3rem'};

        &:first-child {
          border-right: ${isborderRight &&
        `0.1rem solid ${SearchFilterStyle.BdrColors.searchWrapRight}`};
        }
      `}

      
    ${!isRCPortal &&
      css`
        height: ${inputHeight || '4.6rem'};
        border-top-left-radius: ${bdrcurve && '0.4rem'};
        border-bottom-left-radius: ${bdrcurve && '0.4rem'};

        &:first-child {
          border-right: ${borderRight && 'none'};
        }
      `}

    
    ${isSelectCountry &&
      css`
        &:first-child {
          border-radius: 0;
        }
      `}

    
    ${device.mobileTab} {
        background-color: ${SearchFilterStyle.BgColors.mbSearch};
      }
    `}
`;

export const SearchWrap = styled.div<{
  isHeader?: boolean;
  isMobileHidden?: boolean;
}>`
  display: flex;
  border-radius: 0.4rem;

  ${({ theme: { SearchFilterStyle }, isHeader }) =>
    SearchFilterStyle &&
    css`
      box-shadow: ${isHeader &&
      `0 0.3rem 0.6rem ${SearchFilterStyle.BdrColors.searchWrapShadow}`};
    `}

  ${device.mobileTab} {
    box-shadow: none;
    justify-content: center;
  }
  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      ${device.mobileLg} {
        display: none;
      }
    `}
`;

export const InputWrap = styled.div<{
  isHeader?: boolean;
  isRCPortal?: boolean;
}>`
  width: ${({ isHeader }) => (isHeader ? '28.5rem' : '100%')};
  flex-grow: 1;
  ${device.mobileTab} {
    max-width: 25rem;
  }
  ${device.mobileLg} {
    max-width: ${({ isRCPortal }) => (isRCPortal ? '20rem' : '15rem')};
  }
`;

export const Wrap = styled.div`
  position: relative;
`;

export const SearchResultWrap = styled.div`
  position: absolute;
  ${Mixin.Position({ value: '100% auto auto 0.1rem' })}
  z-index: 10;
  width: 100%;
  ${({ theme: { SearchFilterStyle } }) =>
    SearchFilterStyle &&
    css`
      background-color: ${SearchFilterStyle.BgColors.searchResultWrap};
      border: 0.1rem solid ${SearchFilterStyle.BdrColors.searchResultWrapBrdr};
      border-top: 0;
    `}
`;

export const ResultListWrap = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  .light-font {
    font-weight: 400;
  }
`;

export const StaticListWrap = styled.li<{
  bdrbottom?: boolean;
  extraTopSpace?: boolean;
  extraBottomSpace?: boolean;
  isAddStaticSearchList?: boolean
}>`
  padding: 0.5rem 1rem;
  ${({ theme: { SearchFilterStyle }, bdrbottom }) =>
    SearchFilterStyle &&
    bdrbottom &&
    css`
      border-bottom: ${bdrbottom &&
      `0.1rem solid ${SearchFilterStyle.BdrColors.staticListBrdr}`};
    `}
  padding-top: ${({ extraTopSpace }) => extraTopSpace && '1rem'};
  padding-bottom: ${({ extraBottomSpace }) => extraBottomSpace && '1rem'};

`;

export const ListItem = styled.li<{ highlightIndex?: any }>`
  padding: 0.8rem 2rem;

  ${({ theme: { SearchFilterStyle }, highlightIndex }) =>
    SearchFilterStyle &&
    css`
      color: ${highlightIndex && SearchFilterStyle.Colors.resultHighlight};
      background-color: ${highlightIndex
        ? SearchFilterStyle.BgColors.resultHighlight
        : SearchFilterStyle.BgColors.listItemBg};

      &:hover {
        color: ${SearchFilterStyle.Colors.resultHighlight};
        background-color: ${SearchFilterStyle.BgColors.resultHighlight};
        cursor: pointer;
      }
    `}
  ${device.mobileLg} {
    padding: 0.5rem 1rem;
    ${({ theme: { SearchFilterStyle } }) =>
    SearchFilterStyle &&
    css`
        font-size: ${SearchFilterStyle?.FontSizes?.listItem};
      `};
  }
`;

export const SearchBtn = styled.button<{
  isHeader?: boolean;
  isSearchFilter?: boolean;
  isRCPortal?: boolean;
}>`
  border: none;
  flex-shrink: 0;
  padding: 0 1.2rem;
  cursor: pointer;

  ${({ theme: { SearchFilterStyle }, isHeader, isRCPortal }) =>
    SearchFilterStyle &&
    css`
      border-top-right-radius: ${SearchFilterStyle.BdrLeftAdnRight
        .searchButton};
      border-bottom-right-radius: ${SearchFilterStyle.BdrLeftAdnRight
        .searchButton};
      border-radius: ${isRCPortal ? '0.3rem' : null};
      color: ${SearchFilterStyle.Colors.searchButton};
      background-color: ${SearchFilterStyle.BgColors.searchButton};
      font-size: ${SearchFilterStyle.FontSizes.searchButton};
      border: 0.1rem solid
        ${isHeader
        ? SearchFilterStyle.BdrColors.transSearch
        : SearchFilterStyle.BdrColors.searchBtnWrap};
      &:focus {
        outline-offset: 0;
      }
      ${isRCPortal &&
      css`
        &:hover {
          background: ${SearchFilterStyle?.BgColors?.searchButtonHover};
          color: ${SearchFilterStyle?.Colors?.searchButtonHover};
        }
      `}
    `}
  ${device.mobileTab} {
    padding: 0.6rem 0.7rem;
  }

  ${({ isSearchFilter, isRCPortal }) =>
    isSearchFilter &&
    isRCPortal &&
    css`
      ${Mixin.AllCenter({ align: 'center', justify: 'center' })}
      height: ${isRCPortal ? '4.2rem' : '3.86rem'};
      width: ${isRCPortal ? '4.2rem' : '4.6rem'};
      margin-left: ${isRCPortal ? '1rem' : null};
      ${device.tabletMd} {
        height: 3.4rem;
        width: 3.6rem;
      }
    `}
  ${device.mobileLg} {
    ${({ isRCPortal }) =>
    isRCPortal
      ? css`
            height: initial;
            width: 4rem;
          `
      : css`
            margin: 0;
          `}
  }
`;

export const SearchHeading = styled.li`
  text-transform: uppercase;
  cursor: default;
  padding: 0.8rem 1.6rem 0.8rem 2rem;
  ${({ theme: { SearchFilterStyle } }) =>
    SearchFilterStyle &&
    css`
      color:${SearchFilterStyle.Colors.searchHead}
      border-bottom: 0.1rem solid ${SearchFilterStyle.BdrColors.searchHeadBrdr};
    `}
`;

//EXPERIMENT CHANGES RM-1105
export const StaticListWrapExp = styled.li<{
  isAddStaticSearchList?: boolean
}>`
  padding: 0 0.8rem;
  img{
    width: 1.7rem;
    margin-right: 0.8rem;
  }
  .link-icon{
    margin-right: 0.8rem;
  }
  a{
  color:#212529;
  padding: 0.8rem 0 0.8rem 1.6rem;
  display: block;
  ${device.mobileLg}{
    padding: 0.5rem;
    font-size: 1.4rem;
  }
  }
  &:first-child a,&:nth-child(3) a{
    border-bottom: 0.1rem solid #919397;
  }

`;