import {
  mapQueryParams,
  queryStringManipulation,
} from '@components/common/filterHelper';
import { useOutsideClick } from 'helper/hooks/useOutsideClick';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import {
  InputSearch,
  ListItem,
  ResultListWrap,
  SearchHeading,
  SearchResultWrap,
  StaticListWrap,
  StaticListWrapExp,
  Wrap,
} from '../styled';
interface Props {
  id?: string;
  isHeader?: boolean;
  placeholder?: string;
  searchParam: string;
  changeHandler: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  selectSearchResult: (_e: React.SyntheticEvent, _result: string) => void;
  searchDataOnClick?: (_e: React.SyntheticEvent) => void;
  bdrcurve?: boolean;
  searchResults?: any;
  setSearchParam?: any;
  setSearchResults?: any;
  searchStaticList?: any;
  height?: string;
  borderRight?: boolean;
  inputRef?: any;
  setInputVal?: any;
  ariaLabel?: string;
  isRCPortal?: boolean;
  isborderRight?: boolean;
  showListOnFocus?: boolean;
  isSelectCountry?: any;
  openList?: boolean;
  setOpenList?: any;
}

const SearchInput = (props: Props) => {
  const { searchResults, setSearchResults, inputRef, openList, setOpenList } =
    props;
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const searchIndex =
          selectedIndex < searchResults.length - 1
            ? selectedIndex + 1
            : selectedIndex;
        setSelectedIndex(searchIndex);
        props.setInputVal(searchResults[searchIndex], event as any);
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const searchIndex =
          selectedIndex > 0 ? selectedIndex - 1 : selectedIndex;
        setSelectedIndex(searchIndex);
        props.setInputVal(searchResults[searchIndex], event as any);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (selectedIndex !== -1 && searchResults[selectedIndex]) {
          // setSearchParam(searchResults[selectedIndex]);
          props.changeHandler(event as any);
          setSelectedIndex(selectedIndex);
          setSearchResults([]);
        } else {
          props.searchDataOnClick &&
            props.searchDataOnClick(event as React.SyntheticEvent);
        }
      } else if (event.key === 'Tab') {
        setSelectedIndex(-1);
        setSearchResults([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResults, selectedIndex, props.searchParam]
  );

  const isOutsideClick = useOutsideClick(inputRef, null, [], true);

  useEffect(() => {
    if (isOutsideClick) {
      setSearchResults([]);
    } else if (
      !props ||
      Array.isArray(props) ||
      !props.searchParam ||
      props.searchParam.length == 0
    ) {
      setOpenList(false);
    }
    if (isOutsideClick && props.showListOnFocus) {
      setOpenList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutsideClick, props.searchParam]);

  const handleGetLocation = () => {
    const existingQueryParams = mapQueryParams(router.query);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latlong = `${
            JSON.stringify(position.coords.latitude) +
            ',' +
            JSON.stringify(position.coords.longitude)
          }`;
          existingQueryParams['Loc.LatLng'] = [latlong || '0,0'];
          existingQueryParams['Loc.Radius'] = ['30'];
          let queryString: string =
            queryStringManipulation(existingQueryParams);
          document.location.href =
            '/remote-jobs/search' + queryString.slice(0, -1);
        },
        (error) => {
          console.error('Error getting geolocation:', error.message);
        },
        { enableHighAccuracy: true } // Optional: Specify additional options
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleOpenList = () => {
    if (
      props.searchParam.length === 0 &&
      props.id === 'search-by-location' &&
      props.showListOnFocus
    ) {
      setOpenList(true);
    } else {
      setOpenList(false);
    }
  };
  //FOR EXP RM-1105
  const StaticListContents = () => {
    return props.searchStaticList
      ? props.searchStaticList.map((data: any, index: number) => (
          <StaticListWrapExp key={index}>
            {data.name === 'Current Location' ? (
              <a onClick={handleGetLocation} href="#" role="button">
                <i className={'link-icon ' + data.icon}></i>
                {data.name}
              </a>
            ) : (
              <a
                href={data.href}
                onClick={(e: React.SyntheticEvent) =>
                  props.selectSearchResult(e, data.name)
                }
              >
                {data.imageIcon && (
                  <img src={data.imageIcon} alt="search icon" />
                )}
                {data.name}
              </a>
            )}
          </StaticListWrapExp>
        ))
      : props.searchStaticList.map((data: any, index: number) => (
          <StaticListWrap
            key={index}
            bdrbottom={data.border}
            extraTopSpace={data.extraTopSpace}
            extraBottomSpace={data.extraBottomSpace}
          >
            {data.name === 'Current Location' ? (
              <a onClick={handleGetLocation} href="#" role="button">
                <i className={data.icon}></i>&nbsp; {data.name}
              </a>
            ) : (
              <a
                href={data.href}
                onClick={(e: React.SyntheticEvent) =>
                  props.selectSearchResult(e, data.name)
                }
              >
                {<i className={data.icon}></i>}
                &nbsp; {data.name}
              </a>
            )}
          </StaticListWrap>
        ));
  };

  return (
    <Wrap ref={inputRef}>
      <InputSearch
        id={props.id}
        onFocus={() => handleOpenList()}
        isHeader={props.isHeader}
        bdrcurve={props.bdrcurve}
        type="text"
        placeholder={props.placeholder}
        value={props.searchParam}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.changeHandler(e)
        }
        onKeyDown={handleKeyDown}
        inputHeight={props.height}
        borderRight={props.borderRight}
        autoComplete="off"
        aria-expanded="true"
        aria-controls={`${props.id}-listbox`}
        aria-activedescendant={'search-result-' + selectedIndex}
        aria-autocomplete="list"
        role="combobox"
        isRCPortal={props.isRCPortal}
        isborderRight={props.isborderRight}
        isSelectCountry={props.isSelectCountry}
      />
      <div id={`${props.id}-listbox`}>
        {((props.showListOnFocus && openList) ||
          props.searchResults.length > 0) && (
          <SearchResultWrap>
            <ResultListWrap role="listbox">
              {props.searchStaticList && StaticListContents()}
              {props.isHeader && (
                <SearchHeading>
                  <i className="fa fa-briefcase"></i>&nbsp;&nbsp;Jobs
                </SearchHeading>
              )}
              {props.searchResults.map((result: any, index: number) => (
                <>
                  <ListItem
                    id={'search-result-' + index}
                    key={index}
                    onClick={(e: React.SyntheticEvent) =>
                      props.selectSearchResult(e, result)
                    }
                    highlightIndex={index == selectedIndex}
                    aria-selected={index == selectedIndex}
                    role="option"
                  >
                    {result}
                  </ListItem>
                </>
              ))}
              {props.isHeader && (
                <>
                  <SearchHeading>
                    <i className="far fa-rectangle-list"></i>&nbsp;&nbsp;JOB
                    CATEGORIES
                  </SearchHeading>
                  <SearchHeading>
                    <i className="fal fa-newspaper"></i>
                    &nbsp;&nbsp;ARTICLES
                  </SearchHeading>
                </>
              )}
            </ResultListWrap>
          </SearchResultWrap>
        )}
      </div>
    </Wrap>
  );
};

export default SearchInput;
