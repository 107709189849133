import Toaster from '@components/common/toaster';
import { Button, FormGroup, Popup } from '@license-admin/boldfjcomponents';
import { useState } from 'react';
import { CheckBoxWrap, PopUpContent, PopupFooter } from './styled';
interface IProps {
  searchKeyword: string;
  location: string;
  handleCloseModal: () => void;
  handleSaveSearch: (_name: string, _emialAlert: boolean) => Promise<boolean>;
}

interface IFormData {
  searchName: string;
  emailAlert: boolean;
  errMsg: string;
}

export const SaveSearchModal = (props: IProps) => {
  const [formData, setFormData] = useState<IFormData>({
    searchName:
      props.searchKeyword +
        `${props.searchKeyword && props.location ? ' - ' : ''}` +
        props.location || '',
    emailAlert: true,
    errMsg: '',
  });

  const onSearchNameChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = ev.target;

    const newFormData: IFormData = { ...formData };
    newFormData.searchName = value;

    setFormData(newFormData);
  };

  const onEmailAlertChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked } = ev.currentTarget;

    const newFormData: IFormData = { ...formData };
    newFormData.emailAlert = checked;

    setFormData(newFormData);
  };

  const validateFormData = (): boolean => {
    let isFormDirty: boolean = false;

    if (!formData.searchName?.trim()) {
      isFormDirty = true;
      setFormData((prevState) => {
        return {
          ...prevState,
          searchName: '',
          errMsg: 'Search Name is required',
        };
      });
    }

    return isFormDirty;
  };

  const handleSubmit = async () => {
    if (!validateFormData()) {
      const success = await props.handleSaveSearch(
        formData.searchName,
        formData.emailAlert
      );
      if (!success) {
        setFormData((prevState) => {
          return {
            ...prevState,
            searchName: '',
            errMsg: 'That search name already exists.',
          };
        });
      }
    }
  };
  return (
    <Popup
      isPopuphead
      heading="Create a Saved Search"
      closeModal={() => props.handleCloseModal()}
    >
      <PopUpContent>
        <FormGroup
          type="text"
          placeholder="Search Name"
          id="search"
          label="Saved Search Name"
          value={formData.searchName}
          autofocus={true}
          maxLength={50}
          updateFieldData={(ev: React.ChangeEvent<HTMLInputElement>) =>
            onSearchNameChange(ev)
          }
          autoComplete="off"
        />
        <CheckBoxWrap>
          <FormGroup
            type="checkbox"
            value="checkbox"
            aria-label="Email Job Notifications"
            id="Email Job Notifications"
            updateFieldData={(ev: React.ChangeEvent<HTMLInputElement>) =>
              onEmailAlertChange(ev)
            }
            defaultchecked={formData.emailAlert}
          />
          Email Me Job Notifications
        </CheckBoxWrap>
        {formData.errMsg && (
          <Toaster
            role="alert"
            description={formData.errMsg}
            status="alertLight"
            icon="fa-exclamation-triangle"
          />
        )}
        <PopupFooter>
          <Button text="Save Search" clickHandler={() => handleSubmit()} />
        </PopupFooter>
      </PopUpContent>
    </Popup>
  );
};
